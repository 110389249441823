<template>
  <b-card :title="`${$t('Withdraw Warehouse')} ${$t('General.Report')}`">
    <validation-observer ref="simpleRules">
      <b-overlay
        :show="showOverLay"
        rounded="sm"
        :opacity="0.5"
      >
        <b-form ref="formName">
          <b-row>
            <b-col md="3">
              <b-form-group
                :label="$t('General.Start Date')"
                label-for="startDate"
              >
                <flat-pickr
                  v-model="start_date"
                  class="form-control"
                  :placeholder="$t('General.Start Date')"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                :label="$t('General.End Date')"
                label-for="endDate"
              >
                <flat-pickr
                  v-model="end_date"
                  class="form-control"
                  :placeholder="$t('General.End Date')"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                :label="$t('General.Due Date From')"
                label-for="startDate"
              >
                <flat-pickr
                  v-model="start_due_date"
                  class="form-control"
                  :placeholder="$t('General.Due Date From')"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                :label="$t('General.Due Date To')"
                label-for="endDate"
              >
                <flat-pickr
                  v-model="end_due_date"
                  class="form-control"
                  :placeholder="$t('General.Due Date To')"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                :label="$t('Branch')"
                label-for="branch"
              >
                <v-select
                  v-model="branch_id"
                  :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                  :options="branch_option"
                  :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                  :placeholder="$t('Branch')"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                :label="$t('Warehouse')"
                label-for="warehouse"
              >
                <v-select
                  v-model="warehouse_id"
                  :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                  :options="warehouse_option"
                  :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                  :placeholder="$t('Warehouse')"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                :label="$t('Financial Year')"
                label-for="financialYear"
              >
                <v-select
                  v-model="financial_year_id"
                  :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                  :options="financial_year_option"
                  label="name"
                  :placeholder="$t('Financial Year')"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                :label="$t('Client')"
                label-for="client"
              >
                <v-select
                  v-model="client_id"
                  :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                  :options="client_option"
                  :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                  :placeholder="$t('Client')"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                :label="$t('Based Document')"
                label-for="basedDocument"
              >
                <v-select
                  v-model="document_id"
                  :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                  :options="document_option"
                  :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                  :placeholder="$t('Based Document')"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                :label="$t('Status')"
                label-for="status"
              >
                <v-select
                  v-model="status_id"
                  :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                  :options="status_option"
                  :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                  :placeholder="$t('Status')"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('Item')"
                label-for="item"
              >
                <v-select
                  v-model="item_id"
                  :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                  :options="item_option"
                  :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                  :placeholder="$t('Item')"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('Employee')"
                label-for="employee"
              >
                <v-select
                  v-model="employee_id"
                  :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                  :options="employee_option"
                  :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                  :placeholder="$t('Employee')"
                />
              </b-form-group>
            </b-col>
            <b-col cols="3" />
            <b-col cols="6 text-center p-1">
              <b-button
                variant="primary"
                type="submit"
                block
                @click.prevent="validationForm"
              >
                {{ $t('Search') }}
              </b-button>
            </b-col>
            <b-col cols="3" />
            <b-col md="12">
              <!-- Balance Sheet TABLE -->
              <b-card
                no-body
                class="border mt-1"
              >
                <b-card-header class="p-1">
                  <b-card-title class="font-medium-2">
                    <feather-icon
                      icon="BookOpenIcon"
                      size="18"
                    />
                    <span class="align-middle ml-50">{{ `${$t('Withdraw Warehouse')} ${$t('General.Report')}` }}</span>
                  </b-card-title>
                </b-card-header>
                <b-table
                  striped
                  responsive
                  class="mb-0"
                  :items="report_data"
                >
                  <template #cell(date)="data">
                    {{ new Date(data.value).toDateString() }}
                  </template>
                  <template #cell(due_date)="data">
                    {{ new Date(data.value).toDateString() }}
                  </template>
                  <template #cell()="data">
                    {{ data.value }}
                  </template>
                </b-table>
              </b-card>
            </b-col>
            <b-col cols="6">
              <!-- alert -->
              <b-alert
                v-height-fade.appear
                variant="danger"
                :show="showError"
                class="text-left p-1"
              >
                <ul class="list-style-icons mb-0">
                  <li><feather-icon icon="ArrowRightIcon" />{{ apiErrors }}</li>
                </ul>
              </b-alert>
            </b-col>
            <b-col cols="3" />
            <b-col cols="3" />
          </b-row>
        </b-form>
      </b-overlay>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationObserver, localize } from 'vee-validate'
import {
  BFormGroup, BForm, BRow, BCol, BButton, BCard, BOverlay, BAlert, BTable, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightFade } from '@core/directives/animations'
import flatPickr from 'vue-flatpickr-component'
import reportSale from '@/service/sale/sale-report'
import branch from '@/service/administration/branch'
import warehouse from '@/service/warehouse/warehouse'
import financialYear from '@/service/accounting/financial-year'
import client from '@/service/crm/supplier'
import document from '@/service/administration/document'
import status from '@/service/administration/status'
import item from '@/service/warehouse/item'
import employee from '@/service/administration/employee'
import vSelect from 'vue-select'

export default {
  components: {
    ValidationObserver,
    BCard,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BOverlay,
    BTable,
    BAlert,
    BCardHeader,
    BCardTitle,
    flatPickr,
    vSelect,
  },
  directives: {
    'height-fade': heightFade,
  },
  data() {
    return {
      start_date: null,
      end_date: null,
      start_due_date: null,
      end_due_date: null,
      required,
      report_data: [],
      branch_id: '',
      branch_option: [],
      warehouse_id: '',
      warehouse_option: [],
      financial_year_id: '',
      financial_year_option: [],
      client_id: '',
      client_option: [],
      document_id: '',
      document_option: [],
      status_id: '',
      status_option: [],
      item_id: '',
      item_option: [],
      employee_id: '',
      employee_option: [],
      showError: false,
      showOverLay: false,
      apiErrors: [],
      locale: this.$i18n.locale,
    }
  },
  async created() {
    this.getApiSelect()
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.getReportApi()
        }
        this.switchLoc()
      })
    },
    async getReportApi() {
      this.showOverLay = true
      this.report_data = []
      console.log(this.document_id)
      await reportSale.repostAxios({
        report_no: 2,
        from_date: this.start_date,
        to_date: this.end_date,
        from_due_date: this.start_due_date,
        to_due_date: this.end_due_date,
        organization_branch_id: this.branch_id.id ? this.branch_id.id : '',
        warehouse_id: this.warehouse_id ? this.warehouse_id.id : '',
        financial_year_id: this.financial_year_id ? this.financial_year_id.id : '',
        vendor_set_id: this.client_id ? this.client_id.id : '',
        based_document_type_id: this.document_id ? this.document_id.id : '',
        item_id: this.item_id ? this.item_id.id : '',
        user_id: this.employee_id ? this.employee_id.user_id : '',
        status_id: this.status_id ? this.status_id.id : '',
      }).then(response => {
        this.report_data = response.data
        this.showError = false
        this.showToast(this.$t('General.Successfully'), 'CheckCircleIcon', this.$t('General.ReportSuccessfully'), 'success')
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
        this.showToast(this.$t('General.Error'), 'XCircleIcon', this.$t('General.ReportError'), 'danger')
      }).finally(() => { this.showOverLay = false })
    },
    showToast(title, icon, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    },
    switchLoc() {
      this.locale = this.$i18n.locale
      localize(this.locale)
    },
    async getApiSelect() {
      this.showOverLay = true
      await branch.getAxios().then(response => {
        this.branch_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await warehouse.getAxios().then(response => {
        this.warehouse_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await financialYear.getAxios().then(response => {
        this.financial_year_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await client.searchAxios(2).then(response => {
        this.client_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await document.getAxios().then(response => {
        this.document_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await status.getAxios().then(response => {
        this.status_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await item.getAxios().then(response => {
        this.item_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
      await employee.getAxios().then(response => {
        this.employee_option = response.data
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      }).finally(() => { this.showOverLay = false })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
